*, *::before, *::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 18px;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.8rem;
  font-family: 'Ubuntu', sans-serif;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none;
}
